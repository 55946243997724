import React from "react";

const Card = ({ image, title, description, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block w-full"
    >
      <div className="flex bg-white hover:shadow-md transition transform hover:scale-105 w-full p-6 border border-[#003399] cursor-pointer rounded-lg">
        {/* Square Image on Left */}
        <img src={image} alt={title} className="w-40 h-40 object-cover rounded-lg" />
        
        {/* Content on Right */}
        <div className="ml-6 flex flex-col justify-center">
          <h2 className="text-lg font-semibold text-[#003399]">{title}</h2>  
          <p className="text-black text-sm mt-1">{description}</p>  
        </div>
      </div>
    </a>
  );
};

export default Card;
