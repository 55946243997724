import React from "react";
import Card from "./Card";
import SmallCard from "./SmallCard";

export default function Home() {
  const experiences = [
    {
      image: `${process.env.PUBLIC_URL}/images/aws.jpg`,
      title: "Amazon Web Services",
      description: "SDE Intern, S3 Glacier (2024)",
      link: "https://aws.amazon.com/s3/glacier/",
    },
    {
      image: `${process.env.PUBLIC_URL}/images/primarius.jpg`,
      title: "Primarius Technology",
      description: "Research Intern (2023)",
      link: "https://www.primarius-tech.com/en.php",
    },
    {
      image: `${process.env.PUBLIC_URL}/images/roadflex.jpg`,
      title: "RoadFlex",
      description: "Head of Credit Underwriting (2022)",
      link: "https://www.roadflex.com/",
    },
    {
      image: `${process.env.PUBLIC_URL}/images/victoris.jpg`,
      title: "Victoris",
      description: "Founder, Education platform",
    },
  ];  

  const projects = [
    {
      image: `${process.env.PUBLIC_URL}/images/paper1.jpg`,
      title: "Zero-Shot Reference-Based Image Editing Without Training or Guidance",
      link: `${process.env.PUBLIC_URL}/zero_shot_reference_based_image_editing_without_training_or_guidance.pdf`,
    },
    {
      image: `${process.env.PUBLIC_URL}/images/paper2.jpg`,
      title: "Fine-Grained Object Transfer for Complex Image Editing via Iterative Collective Cross Attention",
      link: `${process.env.PUBLIC_URL}/Fine_Grained_Object_Transfer_for_Complex_Image_Editing_via_Iterative_Collective_Cross_Attention.pdf`,
    },
  ];

  const smallProjects = [
    { image: `${process.env.PUBLIC_URL}/images/imagefilter.jpg`, title: "Image Filters", link: "https://kelvin-li-18.github.io/cs180-proj2/" },
    { image: `${process.env.PUBLIC_URL}/images/facemorph.jpg`, title: "Face Morphing", link: "https://kelvin-li-18.github.io/cs180-proj3/" },
    { image: `${process.env.PUBLIC_URL}/images/imagestitching.jpg`, title: "Image Stitching", link: "https://kelvin-li-18.github.io/cs180-proj4/" },
    { image: `${process.env.PUBLIC_URL}/images/diffusion.jpg`, title: "Diffusion Models", link: "https://kelvin-li-18.github.io/cs180-proj5/" },
    { image: `${process.env.PUBLIC_URL}/images/fm.jpg`, title: "Flow Matching", link: "https://kelvin-li-18.github.io/fm/" },
  ];

  return (
    <div className="min-h-screen bg-white flex flex-col items-center px-6 font-medium">
      {/* Hero Section */}
      <div className="max-w-3xl text-center mt-16">
        <h1 className="text-5xl font-normal text-black">Kelvin Li</h1>  
        <p className="text-black mt-3 text-lg">
          Hi, I am an undergraduate at UC Berkeley, studying EECS and Mathematics.
        </p>
        <div className="mt-5 flex justify-center space-x-6">
          <a 
            href="https://www.linkedin.com/in/kelvin-li-jm/" 
            className="text-black hover:text-[#003399]"
            target="_blank" 
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          <a 
            href="mailto:kelvin.li.jm@gmail.com" 
            className="text-black hover:text-[#003399]"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Email
          </a>
        </div>
      </div>

      {/* Experience Cards - First Row */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl">
      {experiences.map((exp, index) => (
          <Card key={index} {...exp} />
        ))}
      </div>

      {/* Research & Victoris - Second Row */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl">
        {projects.map((project, index) => (
          <Card key={index} {...project} />
        ))}
      </div>

      {/* Divider */}
      <div className="w-36 border-b-4 border-gray-300 my-16"></div>

      {/* Small Projects Title */}
      <h2 className="text-2xl font-semibold text-black">Other Projects</h2>  

      {/* Small Projects - Grid */}
      <div className="mt-8 flex flex-row gap-6 justify-center max-w-6xl mb-24">
        {smallProjects.map((proj, index) => (
          <SmallCard key={index} {...proj} />
        ))}
      </div>
    </div>
  );
}
