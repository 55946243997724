import React from "react";

const SmallCard = ({ image, title, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block w-full"
    >
      <div className="flex flex-col items-center hover:scale-105 transition transform hover:shadow-md p-4 rounded-lg border border-[#003399] bg-white cursor-pointer">
        <img src={image} alt={title} className="w-40 h-40 object-cover rounded-lg" />
        <p className="text-black text-xs mt-3 font-medium">{title}</p>
      </div>
    </a>
  );
};

export default SmallCard;
